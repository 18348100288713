// config.js
const config = {
  featureFlags: {
    showModalToStudents: true,
    showModalToStudentsAndCompanies: true,
    showStudentLoginButton: true,
  },
};

export default config;
