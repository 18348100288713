import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../ui/button/Button";

import { Modal } from "antd";

import axios from "axios";

export const uploadFile = async (formsData) => {
  const email = sessionStorage.getItem("email");
  try {
    const response = await axios.post(`/api/uploadfile/${email}`, formsData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024, // 200 MB
      maxBodyLength: 200 * 1024 * 1024, // 200 MB
    });
    console.log("response: " + response);
    return response.status;
  } catch (error) {
    console.error(error);
    return error.response?.status || 500;
  }
};

const UploadImages = (state) => {
  const [email, setEmail] = useState(() => sessionStorage.getItem("email"));
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [uploadAttachment, setUploadAttachment] = useState();
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [syncandshare, setSyncandshare] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const storedEmail = sessionStorage.getItem("email");
      try {
        const response = await axios.get(
          `/api/getAuthorizedUser/${storedEmail}`
        );
        const usrType = response.data.type;
        if (usrType === "Student") {
          setSyncandshare(
            "https://syncandshare.lrz.de/preparefilelink?folderID=2Fnmm6NQYhxdcrarHCLLj"
          );
        } else {
          setSyncandshare(
            "https://syncandshare.lrz.de/preparefilelink?folderID=2V3znmgMfLX8ujuFgVcQd"
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!uploadAttachment) {
      setUploadError("Please select a file to upload.");
      return;
    }

    setIsUploading(true); // Start loading
    setUploadError(""); // Clear previous errors
    setUploadSuccess(false); // Reset success message

    // let email = localStorage.getItem("email");
    const fileData = new FormData();
    fileData.append("file", uploadAttachment);
    const status = await uploadFile(fileData);

    setIsUploading(false); // Stop loading

    console.log(status);
    if (status === 201) {
      setUploadSuccess(true);
    } else if (status === 400) {
      setUploadError("Invalid request. Please check your file and try again.");
    } else if (status === 404) {
      setUploadError("Profile not found. Please log in again.");
    } else if (status === 500) {
      setUploadError("Internal server error. Please try again later.");
    } else {
      setUploadError("An unexpected error occurred. Please try again.");
    }

    setUploadAttachment("");
  };

  const uploadToClient = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Validate file size (100MB limit)
      if (file.size > 100 * 1024 * 1024) {
        setUploadError(
          "File size exceeds the 100MB limit. Please upload a smaller file."
        );
        return;
      }

      // Validate file type
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/jpg",
        "video/mp4",
        "video/quicktime", // Correct MIME type for .mov files
        "video/avi",
      ];
      if (!allowedTypes.includes(file.type)) {
        setUploadError(
          "Invalid file type. Only image and video files are allowed."
        );
        return;
      }

      setUploadAttachment(file);
      setUploadError("");
    }
  };

  const handleBack = () => {
    history.push("/student-profile-actions");
  };

  // Inline styles
  const styles = {
    container: {
      backgroundColor: "#ffffff",
      padding: "2rem",
      maxWidth: "450px",
      width: "90%",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "1rem",
    },
    heading: {
      marginBottom: "1rem",
      fontSize: "1.5rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "1rem",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      // fontWeight: "bold",
      fontSize: "18px",
      color: "#333",
    },
    input: {
      width: "100%",
      padding: "0.5rem",
      fontSize: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    button: {
      width: "100%",
      padding: "0.7rem",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#64A0C8",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },

    message: {
      marginTop: "1rem",
      color: "#28a745",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Upload Image or Video</h2>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleUpload}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Select file:</label>
          <p style={{ fontSize: "14px", color: "#333" }}>
            <strong>
              Ensure that you follow the file name convention: team_company.png
            </strong>
          </p>
          <p style={{ fontSize: "14px", color: "#333" }}>
            <strong>
              The maximum file size is 100MB. If your file is larger than this,
              please upload it here "{syncandshare}"
            </strong>
          </p>
          <input
            type="file"
            onChange={uploadToClient}
            accept="application/pdf, image/*"
            required
            style={styles.input}
          />
          {uploadError === "" ? null : <p>{uploadError}</p>}
        </div>
        <button type="submit" style={styles.button} disabled={isUploading}>
          {isUploading ? "Uploading..." : "Upload"}
        </button>
      </form>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 0px",
              textAlign: "center",
            }}
          >
            <h2 style={{ color: "#DB6C1F" }}>UPLOAD SUCCESSFULL</h2>
          </div>
        }
        open={uploadSuccess}
        closable={false}
        keyboard={false}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <Button
              text="GO BACK TO DASHBOARD"
              onClick={handleBack}
              variant={"secondary"}
              style={{ color: "white" }}
            />
          </div>,
        ]}
      >
        <p
          style={{
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Thank you for your upload
        </p>
      </Modal>
    </div>
  );
};

export default UploadImages;
