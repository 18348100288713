import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./ant-components/routes/PrivateRoute";
import ProtectedRoute from "./ant-components/routes/ProtectedRoute";
import PrivateAdminRoute from "./ant-components/routes/PrivateAdminRoute";

import Dashboard from "./ant-components/pages/dashboard/Dashboard";
import CreateProfile from "./ant-components/pages/create-profile/CreateProfile";

import SSOLogin from "./ant-components/auth/SSOLogin";
import LoginView from "./ant-components/pages/landing/LoginView";

import "./App.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import AntHeader from "./ant-components/layout/header/Header";
import AntFooter from "./ant-components/layout/footer/Footer";
import ViewStudentProfiles from "./ant-components/pages/view-student-profiles/ViewStudentProfiles";
import AntBanner from "./ant-components/ui/banner/Banner";
import { RegisterCompany } from "./ant-components/pages/register-company/RegisterCompany";
import Terms from "./ant-components/pages/create-profile/Terms";
import ViewCompanyProfiles from "./ant-components/pages/view-company-profiles/ViewCompanyProfiles";
import Posters from "./ant-components/pages/posters/PostersPage";
import Matching from "./ant-components/pages/matching-students-companies/Matching";
import StartMatchingResults from "./ant-components/pages/matching-students-companies/StartMatchingResults";
import UploadPosters from "./ant-components/pages/upload-file/UploadPosters";
import UploadImages from "./ant-components/pages/upload-file/UploadImages";
import CreateTeam from "./ant-components/pages/team/CreateTeam";
import ViewPosters from "./ant-components/pages/dashboard/ViewPosters";
import ViewImages from "./ant-components/pages/dashboard/ViewImages";
import ViewTeams from "./ant-components/pages/team/ViewTeams";
import TestUploadAdmin from "./ant-components/pages/dashboard/TestUploadAdmin";
import Login from "./ant-components/pages/login-signup/Login";
import StudentProfileActions from "./ant-components/pages/dashboard/StudentProfileActions";
import AddUsersFromExcel from "./ant-components/pages/dashboard/AddUsersFromExcel";

function App() {
  return (
    <div className="app-container">
      <Router>
        <AntBanner />
        <AntHeader />
        <div className="content-container">
          <Switch>
            <Route exact path="/" component={LoginView} />
            <Route exact path="/ssologin" component={SSOLogin} />
            <Route exact path="/register-company" component={RegisterCompany} />
            <Route path="/posters" component={Posters} />
            <Route path="/login" component={Login} />
            <ProtectedRoute
              exact
              path="/student-profile-actions"
              component={StudentProfileActions}
            />
            <ProtectedRoute
              exact
              path="/upload-poster"
              component={UploadPosters}
            />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/terms" component={Terms} />

            <PrivateRoute
              exact
              path="/create-profile"
              component={CreateProfile}
            />
            <PrivateRoute
              exact
              path="/view-student-profiles"
              component={ViewStudentProfiles}
            />
            <PrivateRoute
              exact
              path="/add-users"
              component={AddUsersFromExcel}
            />
            <PrivateRoute
              exact
              path="/view-company-profiles"
              component={ViewCompanyProfiles}
            />
            <PrivateRoute exact path="/matching" component={Matching} />
            <PrivateRoute
              exact
              path="/startmatching"
              component={StartMatchingResults}
            />

            <ProtectedRoute
              exact
              path="/upload-image"
              component={UploadImages}
            />
            <PrivateRoute
              exact
              path="/startmatching"
              component={StartMatchingResults}
            />
            <PrivateRoute exact path="/create-team" component={CreateTeam} />
            <PrivateRoute exact path="/view-teams" component={ViewTeams} />
            <PrivateRoute exact path="/view-posters" component={ViewPosters} />
            <PrivateRoute exact path="/view-images" component={ViewImages} />
            <PrivateRoute
              exact
              path="/test-upload-admin"
              component={TestUploadAdmin}
            />
          </Switch>
        </div>
        <AntFooter />
      </Router>
    </div>
  );
}

export default App;
