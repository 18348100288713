import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startMatching } from "../../../actions/applicationActions";
import { useHistory } from "react-router-dom";
import axios from "axios";

const AdminProfileActions = (props) => {
  const { auth } = props;
  const user = props.props.auth.user;
  const dispatch = useDispatch();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const buttonRowStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px", // Space between buttons
  };

  const dashButtonStyles = {
    flex: "1 1 45%", // Ensures two buttons per row on larger screens
  };

  const marginBottomStyle = {
    marginBottom: "1rem",
  };

  const marginTopStyle = {
    marginTop: "1rem",
  };

  //call /api/matching
  // axios({
  //   url: `/api/company/downloadcompanies`,
  //   method: "GET",
  //   responseType: "blob",
  // }).then((response) => {
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", `Registered-Companies.xlsx`);
  //   document.body.appendChild(link);
  //   link.click();
  // });
  // const handleGetMatching = () => {
  //   history.push("/matching", { action: "getMatching" }); // Redirect using push
  // };

  // const handleStartMatching = () => {
  //   history.push("/matching", { action: "startMatching" }); // Redirect using push
  // };

  // Function to close the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSendEmail = () => {
    axios
      .get("/api/company/sendEmail")
      .then((response) => {
        console.log("Email sent");
      })
      .catch((error) => {
        console.log("Error sending email: " + error);
      });
  };

  // Function to handle sending authorized emails
  const handleSendingAuthorizedEmails = async () => {
    try {
      const response = await axios.post("/api/forms/sendAuthorizedEmails");
      // setMessage("Credential Emails sent");
      setModalTitle("Success!");
      setModalContent(
        "Credential Emails have been successfully sent to authorized users."
      );
    } catch (error) {
      setModalTitle("Error!");
      setModalContent(
        "There was an error sending credential emails. Please try again." +
          error
      );
    } finally {
      setIsModalOpen(true);
    }
  };

  return (
    <div style={marginBottomStyle}>
      <h6>Student & Company Information:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/view-student-profiles">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Student Profiles
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-company-profiles">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Company Info
            </Button>
          </Link>
        </div>
      </div>

      <h6 style={marginTopStyle}>Matching:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/matching">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Matching Results
            </Button>
          </Link>
        </div>
        {user.role === "Admin" && (
          <div style={dashButtonStyles}>
            <Link to="/startmatching">
              <Button
                type="default"
                icon={<UserOutlined />}
                className="ant-btn-light"
              >
                Start Matching
              </Button>
            </Link>
          </div>
        )}
      </div>

      {/* <h6 style={marginTopStyle}>Upload Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/upload-poster">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Upload Poster
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/upload-image">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Upload Image
            </Button>
          </Link>
        </div>
      </div> */}
      {/* Modal for feedback */}
      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "#DB6C1F" }}>{modalTitle}</h2>
          </div>
        }
        onCancel={handleModalClose} // This ensures the 'X' button works
        open={isModalOpen}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          {modalContent}
        </p>
      </Modal>
      <h6 style={marginTopStyle}>Send Authorized Emails:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
            onClick={handleSendingAuthorizedEmails}
          >
            Send Email to Students & Companies
          </Button>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/add-users">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Add Users
            </Button>
          </Link>
        </div>
      </div>
      {/* <h6 style={marginTopStyle}>Image and Poster Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/view-images">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Images
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-posters">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View Posters
            </Button>
          </Link>
        </div>
      </div> */}

      {/* <h6 style={marginTopStyle}>Team Options:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/create-team">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Create Team
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/view-teams">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              View All Teams
            </Button>
          </Link>
        </div>
      </div> */}

      <h6 style={marginTopStyle}>Test Upload:</h6>
      <div style={buttonRowStyles}>
        <div style={dashButtonStyles}>
          <Link to="/test-upload-admin">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Test Upload Admin
            </Button>
          </Link>
        </div>
        <div style={dashButtonStyles}>
          <Link to="/student-profile-actions">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Test Student Profile Actions
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileActions;
