import React from "react";
import YoutubeEmbed from "../../ui/embedded-video/YoutubeEmbed";
import Button from "../../ui/button/Button";
import { useState, useEffect } from "react";

const useStyles = (isSmallScreen) => ({
  middleSection: {
    //allign the video in the middle vertically
    marginTop: "1.5%",
    marginLeft: "3%",
    width: "32.5%",
  },
  leftSection: {
    marginTop: "1.5%",
    marginLeft: "5%",
    width: "32.5%",
  },
  rightSection: {
    marginTop: "1.5%",
    marginLeft: "5%",
    width: "32.5%",
  },
  container: {
    display: "flex",
    padding: "30px 0px",
  },
  topSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginLeft: isSmallScreen ? "0" : "40%",
    marginTop: "20px",
    padding: "15px",
    background: "#64A0C8",
    // borderRadius: "20px",
    //boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    // width: isSmallScreen ? "100%" : "20%",
    width: "100%",
    color: "white",
  },
  countdownText: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "10px",
  },
  timeBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  timeUnit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    color: "#64A0C8",
    borderRadius: "8px",
    padding: "8px 12px",
    minWidth: isSmallScreen ? "20px" : "50px",
    fontWeight: "bold",
    fontSize: isSmallScreen ? "14px" : "18px",
    maxWidth: "55px",
  },
  timeLabel: {
    fontSize: "10px",
    fontWeight: "normal",
    color: "#64A0C8",
  },
  separator: {
    fontSize: "24px",
    color: "white",
  },
});

const ProjectWeek = () => {
  const scroll = () => {
    const section = document.querySelector("#why-attend");
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  // State for the countdown timer
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const isSmallScreen = window.innerWidth <= 768; // Example breakpoint for small screens
  const styles = useStyles(isSmallScreen);

  // Set the event date for countdown
  const countDownDate = new Date("2025-01-13T00:00:00").getTime();

  // Update countdown every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countDownDate]);

  return (
    <div>
      {/* <div style={styles.topSection}>
        <div style={styles.countdownText}>Time left until 1.000+ 2025:</div>
        <div style={styles.timeBox}>
          <div style={styles.timeUnit}>
            <div>{timeLeft.days}</div>
            <div style={styles.timeLabel}>Days</div>
          </div>
          <span style={styles.separator}>•</span>
          <div style={styles.timeUnit}>
            <div>{timeLeft.hours}</div>
            <div style={styles.timeLabel}>Hours</div>
          </div>
          <span style={styles.separator}>•</span>
          <div style={styles.timeUnit}>
            <div>{timeLeft.minutes}</div>
            <div style={styles.timeLabel}>Minutes</div>
          </div>
          <span style={styles.separator}>•</span>
          <div style={styles.timeUnit}>
            <div>{timeLeft.seconds}</div>
            <div style={styles.timeLabel}>Seconds</div>
          </div>
        </div>
      </div> */}

      <div className="container" style={{ padding: "30px 0px" }}>
        <div className="left-section">
          {/* <p
          style={{
            fontWeight: 500,
            fontSize: "12px",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            background: "linear-gradient(to right, #F2A408, #DE6811",
            display: "inline-block"
          }}
        >
          Get ready for the closing event on 24th April 2024
        </p> */}
          <h1 className="top-heading">1.000+ PROJECT WEEK 2025</h1>
          <div style={{ padding: "10px 0px" }}>
            <p style={{ fontWeight: 500, fontSize: "18px", color: "#DB6C1F" }}>
              Use the challenge-based learning with Hidden Champions to prepare
              your professional career.
            </p>
            <p style={{ fontWeight: 500, fontSize: "18px", color: "#64A0C8" }}>
              Be part of the project week to have access to TUM Talents and
              promote your business culture.{" "}
            </p>
            {/* <Button
            text="SIGN UP for Project Week 2025 is over - stay tuned..."
            style={{ fontWeight: 600, fontSize: "18px" }}
            variant="primary"
            onClick={scroll}
          /> */}
            <Button
              text="On-site from 13 – 17 JAN 2025"
              style={{ fontWeight: 600, fontSize: "18px" }}
              variant="primary"
              onClick={scroll}
            />

            {/* <p
              style={{
                fontWeight: 400,
                fontSize: "17px",
                color: "#515151",
                paddingTop: "10px",
                margin: 0,
              }}
            >
              <strong>Don't miss the chance to sign up</strong> (student
              registration ends by end of October 2024).
            </p> */}
          </div>
        </div>
        <div className="right-section">
          <YoutubeEmbed embedId="LmfdPAtI_V0" />
        </div>
      </div>
    </div>
  );
};

export default ProjectWeek;
