import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../ui/button/Button";
import { Modal } from "antd";
import axios from "axios";

export const uploadExcelFile = async (formData) => {
  try {
    const response = await axios.post(`api/forms/uploadexcel`, formData);
    return response.status;
  } catch (error) {
    console.error(error);
    return error.response?.status || 500;
  }
};

const AddUsersFromExcel = () => {
  const [message, setMessage] = useState("");
  const [uploadAttachment, setUploadAttachment] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const history = useHistory();

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", uploadAttachment);
    const status = await uploadExcelFile(formData);
    if (status === 201) {
      setUploadSuccess(true);
      setMessage("Users added successfully!");
    } else {
      setUploadError(
        "Failed to upload. Please try again with a valid Excel file."
      );
    }
    setUploadAttachment(null);
  };

  const uploadToClient = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setUploadAttachment(file);
        setUploadError("");
      } else {
        setUploadError("Please select a valid Excel file (.xlsx or .xls).");
      }
    }
  };

  const handleBack = () => {
    history.push("/dashboard");
  };

  const styles = {
    container: {
      backgroundColor: "#ffffff",
      padding: "2rem",
      maxWidth: "400px",
      width: "90%",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "1rem",
    },
    heading: {
      marginBottom: "1rem",
      fontSize: "1.5rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "1rem",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      fontWeight: "bold",
      color: "#555",
    },
    input: {
      width: "100%",
      padding: "0.5rem",
      fontSize: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    button: {
      width: "100%",
      padding: "0.7rem",
      fontSize: "1rem",
      color: "#fff",
      backgroundColor: "#64A0C8",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    message: {
      marginTop: "1rem",
      color: "#28a745",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Add Users from Excel Sheet</h2>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleUpload}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Select Excel File:</label>
          <input
            type="file"
            onChange={uploadToClient}
            accept=".xls, .xlsx"
            required
            style={styles.input}
          />
          {uploadError && <p style={{ color: "red" }}>{uploadError}</p>}
        </div>
        <button type="submit" style={styles.button}>
          Upload
        </button>
      </form>
      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "#DB6C1F" }}>UPLOAD SUCCESSFUL</h2>
          </div>
        }
        open={uploadSuccess}
        closable={false}
        footer={[
          <div style={{ textAlign: "center" }}>
            <Button
              text="GO BACK TO Dashboard"
              onClick={handleBack}
              variant="secondary"
            />
          </div>,
        ]}
      >
        <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}>
          Users have been successfully added from the uploaded Excel sheet.
        </p>
      </Modal>
    </div>
  );
};

export default AddUsersFromExcel;
