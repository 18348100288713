import React from "react";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

const AntBanner = () => {
  const location = useLocation();

  const scroll = () => {
    const section = document.querySelector("#why-attend");
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const displayMenu = location?.pathname == "/" ? true : false;

  if (!displayMenu) {
    return null;
  }

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 999,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 10px",
          textAlign: "center",
        }}
        className="thousand-orange"
      >
        <Text style={{ color: "#fff", fontWeight: "600", fontSize: "14px" }}>
          13 JAN 2025 - 17 JAN 2025
          {/* |{" "} 
          <span
            onClick={scroll}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              marginLeft: "5px",
            }}
          >
            SIGN UP NOW
          </span> */}
        </Text>
      </div>
    </div>
  );
};

export default AntBanner;
