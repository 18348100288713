import React, { useState, useEffect } from "react";
import Button from "../../ui/button/Button";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import axios from "axios";
import { Modal } from "antd";

export const uploadFile = async (formsData) => {
  const email = sessionStorage.getItem("email");

  try {
    const response = await axios.post(`/api/uploadposter/${email}`, formsData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response: " + JSON.stringify(response));
    return response.status;
  } catch (error) {
    console.error(error);
    return error.response?.status || 500;
  }
};

const UploadPosters = ({ state }) => {
  const history = useHistory();

  const [email, setEmail] = useState(() => sessionStorage.getItem("email"));
  const [uploadAttachment, setUploadAttachment] = useState();
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    if (!email) {
      setUploadError("No email found. Please log in again.");
      // history.push("/login"); // Redirect to login if no email is found
    }
  }, [email]);

  const handleBack = () => {
    history.push("/student-profile-actions");
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!uploadAttachment) {
      setUploadError("Please select a file to upload.");
      return;
    }

    let fileData = new FormData();
    fileData.append("file", uploadAttachment);

    const status = await uploadFile(fileData);
    console.log(status);

    if (status === 201) {
      setUploadSuccess(true);
    } else {
      setUploadError(
        "An error has occurred. Please try again after selecting the file again."
      );
    }

    setUploadAttachment("");
  };

  const uploadToClient = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Validate file size (100MB limit)
      if (file.size > 100 * 1024 * 1024) {
        setUploadError(
          "File size exceeds the 100MB limit. Please upload a smaller file."
        );
        return;
      }

      // Validate file type
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        setUploadError("Invalid file type. Only PDF files are allowed.");
        return;
      }

      setUploadAttachment(file);
      setUploadError("");
    }
  };

  return (
    <div>
      <div style={{ overflowY: "auto" }}>
        <div>
          {state ? (
            <h1>Congratulations! You have submitted your data.</h1>
          ) : config.featureFlags.showStudentLoginButton ? (
            <>
              <div style={{ marginLeft: "80px" }}>
                <div style={{ paddingTop: "40px" }}>
                  <h1>THANK YOU FOR TAKING PART IN 1.000+ PROJECT WEEK</h1>
                  <h5>
                    Please upload your poster by first choosing the file and
                    then pressing submit.
                  </h5>
                  <p>
                    Ensure that you follow the file name convention:
                    team_company.pdf
                  </p>
                  <p style={{ fontSize: "14px", color: "#333" }}>
                    <strong>
                      The maximum file size is 100MB. If your file is larger
                      than this, please upload it here
                      "https://syncandshare.lrz.de/preparefilelink?folderID=2EXuKJcJNsS1gYojd7LCH"
                    </strong>
                  </p>
                </div>

                <form onSubmit={handleUpload}>
                  <input
                    type="file"
                    className="text-sm text-stone-500"
                    name="attachment"
                    onChange={uploadToClient}
                  />
                  {uploadError && <p>{uploadError}</p>}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <input
                      type="submit"
                      value="SUBMIT"
                      className={`rounded-button primary`}
                    />
                    <Button
                      text="GO BACK TO DASHBOARD"
                      variant="secondary"
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        fontWeight: "600",
                        fontSize: "14px",
                        marginRight: "60px",
                      }}
                      onClick={handleBack}
                    />
                  </div>
                </form>
                <Modal
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 0px",
                        textAlign: "center",
                      }}
                    >
                      <h2 style={{ color: "#DB6C1F" }}>
                        POSTER UPLOADED SUCCESSFULLY
                      </h2>
                    </div>
                  }
                  open={uploadSuccess}
                  closable={false}
                  keyboard={false}
                  footer={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <Button
                        text="GO BACK TO DASHBOARD"
                        onClick={handleBack}
                        variant={"secondary"}
                        style={{ color: "white" }}
                      />
                    </div>,
                  ]}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    Thank you for submitting your poster to us.
                  </p>
                </Modal>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <h3 style={{ textAlign: "center", paddingBottom: "5px" }}>
                    YOU ARE ALREADY REGISTERED WITH US.{" "}
                  </h3>
                  <h3 style={{ textAlign: "center" }}>
                    PLEASE WAIT FOR THE MATCHING PERIOD TO END. THANK YOU
                  </h3>
                </div>

                <Button
                  text="GO BACK TO DASHBOARD"
                  variant="primary"
                  onClick={handleBack}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadPosters;
