import React, { useState, useEffect } from "react";
// import Button from "../../ui/button/Button";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

// export const uploadFile = async (formsData) => {
//   try {
//     const response = await axios.post(`api/profile/uploadfile`, formsData);
//     console.log("response: " + response);
//     return response.status;
//   } catch (error) {
//     console.error(error);
//     return error.response?.status || 500;
//   }
// };

const StudentProfileActions = ({ state }) => {
  const [usrType, setUsrType] = useState("");

  const buttonRowStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px", // Space between buttons
  };

  const dashButtonStyles = {
    flex: "1 1 45%", // Ensures two buttons per row on larger screens
  };

  const marginBottomStyle = {
    marginLeft: "10rem",
    marginBottom: "1rem",
    marginTop: "2rem",
  };

  const marginTopStyle = {
    marginTop: "1rem",
  };

  useEffect(() => {
    const fetchUserType = async () => {
      const storedEmail = sessionStorage.getItem("email");
      try {
        const response = await axios.get(
          `/api/getAuthorizedUser/${storedEmail}`
        );
        console.log(response.data.type);
        setUsrType(response.data.type);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserType();
  }, []);

  // const handleBack = () => {
  //   history.push("/");
  // };

  // const handleUpload = async (e) => {
  //   e.preventDefault();
  //   const fileData = new FormData();
  //   fileData.append("file", uploadAttachment);
  //   const status = await uploadFile(fileData);
  //   console.log(status);
  //   if (status === 201) {
  //     setUploadSuccess(true);
  //   } else {
  //     setUploadError(
  //       "An error has occured. Please try again after selecting it again"
  //     );
  //   }

  //   setUploadAttachment("");
  // };

  // const uploadToClient = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const i = event.target.files[0];
  //     setUploadAttachment(i);
  //   }
  // };

  return (
    <div style={marginBottomStyle}>
      <h6 style={marginTopStyle}>Upload Options:</h6>
      <div style={buttonRowStyles}>
        {usrType === "Student" ? (
          <div style={dashButtonStyles}>
            <Link to="/upload-poster">
              <Button
                type="default"
                icon={<UserOutlined />}
                className="ant-btn-light"
              >
                Upload Poster
              </Button>
            </Link>
          </div>
        ) : (
          <></>
        )}
        <div style={dashButtonStyles}>
          <Link to="/upload-image">
            <Button
              type="default"
              icon={<UserOutlined />}
              className="ant-btn-light"
            >
              Upload Image/Video
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StudentProfileActions;
